import React from "react";
import { Link } from "react-router-dom";

export default function FrontPage() {
  const close = () => {
    document.getElementById("menues").style.display = "none";
  }
  const open = () => {
    document.getElementById("menues").style.display = "block";
  }
  return (
    <>
      <div className="h-screen hidden lg:block" id="header">
        <div className=" h-[95vh] bg-black pt-16 rounded-b-3xl">
          <div className="Header bg-black h-fit flex justify-end">
            <div className="w-fit text-white">
              <ul className="flex space-x-12 mr-32 font-sans">
                <li className="hover:text-gray-300"><Link to="./">Home</Link></li>
                <li className="hover:text-gray-300"><Link to="/Solutions">Solutions</Link></li>
                <li className="hover:text-gray-300"><a href="#About">About</a></li>
                <li className="hover:text-gray-300"><a href="#Contact">Contact</a></li>
              </ul>
            </div>
          </div>
          <div className="flex h-fit justify-between text-white px-36 items-center py-20">
            <div>
              <h1 className="text-8xl" style={{ fontFamily: "'Archivo Black', sans-serif" }}>
                JSR <br />
                Prime <br />
                Solution
              </h1>
            </div>
            <div className="image w-fit h-[60vh] overflow-hidden">
              <img src="resources/man-image.png" alt="" className="w-[26rem]" />
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden h-screen bg-black text-white pb-10 landscape:h-[100vw] relative">
        <div className="w-[70vw] text-white bg-[rgb(20,20,20)] h-screen fixed z-10 ml-[30vw] hidden landscape:h-[100vw] landscape:w-[40vw] landscape:ml-[60vw] landscape:overflow-y-scroll" id="menues">
        <div className="flex justify-end text-5xl pt-10 pr-10 z-50" onClick={close}>×</div>
          <ul className="font-sans flex flex-col items-center h-full mt-24">
            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="./">Home</Link></li>
            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/Solutions">Solutions</Link></li>
            <li className="hover:text-gray-300 mt-10 text-2xl"><a href="#About">About</a></li>
            <li className="hover:text-gray-300 mt-10 text-2xl"><a href="#Contact">Contact</a></li>
          </ul>
        </div>
        <div className="flex justify-end text-4xl pt-10 pr-10" onClick={open}>☰</div>
        <div>
          <img src="resources/man-image.png" alt="" className="w-[85vw] m-auto mt-10 mb-5 md:w-[50vw]" />
        </div>
        <div>
          <h1 className="text-7xl w-fit m-auto mt-5" style={{ fontFamily: "'Archivo Black', sans-serif" }}>
            JSR <br />
            Prime <br />
            Solution
          </h1>
        </div>
      </div>
    </>
  );
};
