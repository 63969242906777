import React from 'react';
import { Link } from 'react-router-dom';

export default function Form() {
  return (
    <>
      <Link to="/Solutions" className='float-right'>X</Link>
      <form action="#" className='w-fit  border-2 border-black absolute center container rounded-lg md:py-10 md:px-16'>
        <div className='inputbox'>
          <input type="text" name="CompanyName" className='m-5  rounded-lg md:w-[25vw] h-9' required /><span>Company Name</span></div><br />
        <div className='inputbox'><input type="text" name="Name" className='m-5  rounded-lg md:w-[25vw] h-9' required /><span>Name</span></div><br />
        <div className='inputbox'><input type="text" name="Phone" className='m-5  rounded-lg md:w-[25vw] h-9' required /><span>Phone</span></div><br />
        <div className='inputbox'><input type="Email" name="Email" className='m-5  rounded-lg md:w-[25vw] h-9' required /><span>Email</span></div><br />
        <input type="radio" name="buy" id="" className='m-5 border-2 border-black rounded-lg' />Try For Free
        <input type="radio" name="buy" id="" className='m-5 border-2 border-black rounded-lg' />Purchase<br />
        <div className='inputbox'><input type="text" name="GSTIN" id="" className='m-5  rounded-lg md:w-[25vw] h-9' required /><span>GSTIN</span></div><br />
        <input type="submit" value="Submit" className='m-5 border-2 border-black rounded-lg w-[25vw] bg-black text-white' />

      </form>

    </>
  )
}
