import React from 'react'

export default function Chatbox() {
  return (
    <>
        <div className='fixed bottom-5 right-5 lg:right-10 bg-yellow-300 rounded-xl w-fit h-fit flex justify-evenly px-3 py-2 lg:py-3 lg:px-4 cursor-pointer z-50'>
            <img src="resources/chat.png" alt="" className='h-10 lg:h-14 m-auto'/>
            <h1 className="text-center lg:text-xl font-bold">
                Let's<br />
                Chat
            </h1>
        </div>
    </>
  )
}
