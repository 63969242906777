import React from 'react'

export default function Clients() {
  return (
    <div>
      <div className='bg-[rgb(248,248,248)] pb-20 pt-10 mt-20 hidden lg:block'>
        <h1 className='font-extrabold text-5xl w-fit m-auto mb-16'>OUR CLIENT</h1>
        <div className='flex justify-center items-center w-fit m-auto'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo1.png" alt="" /></div>
        <div className='flex w-[30vw] justify-between m-auto'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo4.png" alt="" /><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo5.png" alt="" /></div>
        <div className='flex justify-center items-center w-fit m-auto'><img className='w-36 cursor-pointer grayscale hover:grayscale-0' src="resources/logo2.png" alt="" /></div>
        <div className='flex w-[30vw] justify-between m-auto'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo3.png" alt="" /><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo6.png" alt="" /></div>
    </div>
    <div className='bg-[rgb(248,248,248)] pb-20 pt-10 mt-20 lg:hidden'>
        <h1 className='font-extrabold text-5xl w-fit m-auto mb-16'>OUR CLIENT</h1>
        <div className='flex w-[90vw] justify-between m-auto mb-10'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo4.png" alt="" /><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo5.png" alt="" /></div>
        <div className='flex w-[90vw] justify-between m-auto mb-10'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo6.png" alt="" /><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo1.png" alt="" /></div>
        <div className='flex w-[90vw] justify-between m-auto mb-10'><img className='h-28 cursor-pointer grayscale hover:grayscale-0' src="resources/logo3.png" alt="" /><img className='h-[4rem] cursor-pointer grayscale hover:grayscale-0' src="resources/logo2.png" alt="" /></div>
    </div>
    </div>
  )
}
