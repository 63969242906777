import React from 'react'

export default function about() {
  return (
    <div className='pb-20 pt-10 mt-20' id='About'>
        <h1 className='font-extrabold text-5xl w-fit m-auto mb-16'>About</h1>
        <section id="about" className="flex flex-wrap justify-center w-[80vw] lg:w-[70vw] m-auto flex-col lg:flex-row">
            <div className='mb-10 lg:mb-0 lg:w-1/2 m-auto'><img src="resources/photo.png" alt="" className='rounded-full' /></div>
            <div className='lg:w-1/2'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est ut iusto porro veniam totam, soluta provident ad perferendis. Consequuntur enim deserunt eligendi optio magni officia eum nisi quis? Dicta, repellat? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus, aliquid officiis. Nobis maxime magni veritatis cupiditate eius, laborum voluptatibus ducimus? <br /><br />Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur, sint animi nesciunt odio voluptatem fugiat minus voluptate eaque deleniti, porro ipsa debitis qui? Itaque dolores ipsa debitis voluptatum veniam accusantium?</div>
        </section>
    </div>
  )
}
