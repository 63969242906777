import React from 'react'
import { Link } from 'react-router-dom'

export default function SolutionsPage() {

    const close = () => {
        document.getElementById("menues").style.display = "none";
    }
    const open = () => {
        document.getElementById("menues").style.display = "block";
    }
    return (
        <>
            <div className='hidden lg:block'>
                <div className="Header bg-black h-fit flex justify-between py-8">
                    <div className='w-fit text-white'><h1 className='text-2xl font-extrabold ml-10'>JSR Prime Solution</h1></div>
                    <div className="w-fit text-white">
                        <ul className="flex space-x-12 mr-32 font-sans my-auto">
                            <li className="hover:text-gray-300"><Link to="/">Home</Link></li>
                            <li className="hover:text-gray-300"><Link to="/Solutions">Solutions</Link></li>
                            <li className="hover:text-gray-300"><Link to="/"><a href="#About">About</a></Link></li>
                            <li className="hover:text-gray-300"><Link to="/"><a href="#Contact">Contact</a></Link></li>
                        </ul>
                    </div>
                </div>
                <div className='flex'>
                    <div className='w-1/2 text-center border-2 p-2 hover:inner-shadow-example cursor-pointer'><Link to="/Solutions">Add-Ons</Link></div>
                    <div className='w-1/2 text-center border-b-2 p-2 hover:inner-shadow-example cursor-pointer'><Link to="/Solutions/verticalsolution">Vertical Solutions</Link></div>
                </div>
            </div>
            <div className='lg:hidden'>
                <div className=" h-fit bg-black text-white landscape:h-fit relative flex justify-between pb-5">
                    <div className='w-fit text-white'><h1 className='text-2xl font-extrabold ml-3 mt-5'>JSR Prime Solution</h1></div>
                    <div className="w-[70vw] text-white bg-[rgb(20,20,20)] h-screen fixed z-10 ml-[30vw] hidden landscape:h-[100vw] landscape:w-[40vw] landscape:ml-[60vw] landscape:overflow-y-scroll" id="menues">
                        <div className="flex justify-end text-5xl pt-5 pr-10 z-50" onClick={close}>×</div>
                        <ul className="font-sans flex flex-col items-center h-full mt-24">
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/">Home</Link></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/Solutions">Solutions</Link></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/"><a href="#About">About</a></Link></li>
                            <li className="hover:text-gray-300 mt-10 text-2xl"><Link to="/"><a href="#Contact">Contact</a></Link></li>
                        </ul>
                    </div>
                    <div className="flex justify-end text-4xl pt-5 pr-10" onClick={open}>☰</div>
                </div>
                <div className='flex'>
                    <div className='w-1/2 text-center border-2 p-2 hover:inner-shadow-example cursor-pointer'><Link to="/Solutions">Add-Ons</Link></div>
                    <div className='w-1/2 text-center border-b-2 p-2 hover:inner-shadow-example cursor-pointer'><Link to="/Solutions/verticalsolution">Vertical Solutions</Link></div>
                </div>
            </div>
        </>
    )
}
