import './App.css';
import { useEffect, useState } from 'react';
import SolutionsPage from './components/SolutionsPage';
import Addonspage from './components/Addonspage';
import VerticalSolutions from './components/VerticalSolutions';
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider, Navigate, useNavigate } from 'react-router-dom'
import SolutionsLayout from './components/SolutionsLayout';
import Layout from './components/Layout';
import Form from './components/form';
import Admin from './components/Admin';


function App() {
  const [vS, verticalSolution] = useState([]);
  const [ao, addOns] = useState([]);
  const [Data, setData] = useState({});
  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();

    const response = await fetch("https://jsr-prime.vercel.app/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Data),
    });
    const resp = await response.json();
    console.log(resp)
    if (await resp[0] == true ) {
      sessionStorage.setItem("Login", "True");
      sessionStorage.setItem("name", resp[1])
      navigate("/admin");
    }
    else{
      console.log("abcd");
    }
  }
  const change = (e) => {
    setData({
      ...Data,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://jsr-prime.vercel.app/vertical-solutions", {
          method: "GET",
        });
        const data = await response.json();
        await verticalSolution(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://jsr-prime.vercel.app/add-ons", {
          method: "GET",
        });
        const data = await response.json();
        await addOns(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
        <Routes>
          <Route path='/' element={
            <Layout vs={vS} />
          } />
          <Route path='/Solutions' element={<SolutionsLayout />} >
            <Route index element={<Addonspage add_ons={ao} />} />
            <Route path='verticalsolution' element={<VerticalSolutions solutions={vS} />} />
          </Route>
          <Route path='form' element={<Form />} />
          <Route path='Loginform' element={
            <>
              {
                sessionStorage.getItem('Login') == "True" ? (<Navigate to="/admin" />) : (<form onSubmit={submit} className='w-fit  border-2 border-black absolute center container rounded-lg md:py-5 md:px-5'>
                  <div className='inputbox'>
                    <input type="text" name="Username" className='m-5  rounded-lg md:w-[20vw] h-9' required onChange={change} /><span>User Name</span></div><br />
                  <div className='inputbox'><input type="text" name="Password" className='m-5  rounded-lg md:w-[20vw] h-9' required onChange={change} /><span>Password</span></div><br />
                  <input type="submit" value="Submit" className='m-5 border-2 border-black rounded-lg w-[20vw] bg-green-600 text-white cursor-pointer' />

                </form>)
              }

            </>
          }

          />
          <Route path="admin" element={<>
            {
              sessionStorage.getItem('Login') == "True" ? (<Admin add_ons={ao} solutions={vS} />) : (<Navigate to="/" />)
            }
            
          </>
          } />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
    </>
  );
}

export default App;