import React from 'react'

export default function Contact() {
  return (
    <main>
        <section id="Contact">
            <h1 className="font-extrabold text-5xl text-black m-auto text-center mt-20">Contact</h1>
            <div className="flex w-[90%] justify-center m-auto space-x-14 pt-10 lg:space-x-28">
                <div><img src="/resources/Screenshot 2023-11-22 203706-c4dc5868.png" className="h-12 lg:h-16 m-auto cursor-pointer grayscale hover:grayscale-0" alt=""/>
                    <p className="text-center mt-5"><b>Phone no</b>:-<br/>7897226372</p>
                </div>
                <div><a href="/"><img src="/resources/Screenshot 2023-11-22 203816-0eb7b323.png" className="h-9 lg:h-12 lg:mt-4 m-auto cursor-pointer grayscale hover:grayscale-0" alt=""/><p className="text-center mt-5"><b>Email us</b></p></a>
                </div>
                <div><img src="/resources/Screenshot 2023-11-22 203947-8508f096.png" className="h-12 lg:h-16 m-auto cursor-pointer grayscale hover:grayscale-0" alt=""/>
                    <p className="text-center mt-[18px]"><b>Social</b><br/></p>
                    <div className="flex m-auto social"><a href="/"><img src="/resources/Screenshot 2023-11-22 212947-236c4147.png"
                                alt="" className="h-8 cursor-pointer grayscale hover:grayscale-0"/></a>
                        <a href="https://wa.me/+917897226372"><img src="/resources/Screenshot 2023-11-22 213057-fc3c8af7.png" alt="" className="h-8 cursor-pointer grayscale hover:grayscale-0"/></a>
                        <a href="#"><img src="/resources/Screenshot 2023-11-22 213136-58d99878.png" alt="" className="h-8 cursor-pointer grayscale hover:grayscale-0"/></a>
                    </div>
                </div>
            </div>
        </section>
    </main>   
  )
}
